/** GVF functions */
import {GvfConfigService} from "./gvf-config-service";

class GvfService{
    constructor(){
        this.currentTTimemout = null;
    }

    /**
     * Gets JSON from object
     * @param {*} obj
     * @returns {string}
     */
    toJson(obj){
        if($.isPlainObject(obj) || $.isArray(obj)){
            return JSON.stringify(obj);
        }else{
            return obj;
        }
    }

    /**
     * Reads json string
     * @param {string} obj
     * @returns {Object}
     */
    parseJson(obj){
        if(obj && obj.indexOf && (obj.indexOf("{")> -1 || obj.indexOf("[")> -1)){
            try{
                return JSON.parse(obj);
            }catch(e){
                return obj;
            }
        }else{
            return obj;
        }
    }

    /**
     * Clones object
     * @param {Object} objectInstance
     * @return {Object}
     */
    clone(objectInstance){
        return Object.assign(Object.create(Object.getPrototypeOf(objectInstance)), objectInstance);
    }

    /**
     * Loads given script
     * @param {string} jsUrl
     * @return {jQuery.Promise}
     */
    loadJsScript(jsUrl){
        return new Promise((resolve,reject) => {
            $.ajax({
                method: "GET",
                url: jsUrl,
                dataType: "script",
                success: function(data){
                    resolve(data);
                },
                error: function(error){
                    reject(error);
                }
            });
        });
    }

    /**
     * Loads given css file
     * @param {string} cssUrl
     * @return {jQuery.Promise}
     */
    loadCssFile(cssUrl){
        return new Promise((resolve,reject) => {
            const $head  = $('head').eq(0);
            const $link  = $("<link />");
            $link.attr("rel",'stylesheet');
            $link.attr("type",'text/css');
            $link.attr("href",cssUrl);
            $link.attr("media","all");
            $head.append($link);
            resolve();
        });
    }

    parseUrlParams(params){
        if(params.length>0){
            const assigns = params.split("&");
            const object = {};
            for(let i = 0; i<assigns.length; i++){
                const assign = assigns[i].split("=");
                object[assign[0]] = decodeURIComponent(assign[1]);
            }
            return object;
        }else{
            return {};
        }
    }

    /**
     * Calls a remote endpoint
     * @param {string} path
     * @param {Object} params
     * @param {string?} method
     * @return {Promise<any>}
     */
    endpoint(path,params,method){
        if(path.indexOf("/ep")== -1){
            path = "/ep"+path;
        }
        method = method?method.toUpperCase():"POST";
        if(method!="GET"){
            params = this.toJson(params);
        }
        const apiRootUrl = GvfConfigService.getValue("apiRootUrl");

        return new Promise((resolve,reject) => {
            $.ajax(
                apiRootUrl+path,
                {
                    type: method,
                    url: apiRootUrl+path,
                    contentType: "application/json",
                    data: params,
                    cache: false,
                    processData: (method=="GET"),
                    success: function(data){
                        resolve(data);
                    },
                    error: function(error){
                        reject(error);
                    }
                }
            );
        });
    }

    /**
     * Delays execution
     * @param {int} milliseconds
     * @param {string?} key
     * @return {Promise}
     */
    delay(milliseconds,key){
        if(key){
            this.cancelDelay(key);
        }

        return new Promise(
            (resolve) => {
                let tt = setTimeout(
                    () => {
                        resolve();
                    },
                    milliseconds
                );
                if(key){
                    this.tTimeout(key,tt);
                }
            }
        );
    }

    tTimeout(key,value){
        if(!this.currentTTimemout){
            this.currentTTimemout = {};
        }
        if(value===undefined){
            return this.currentTTimemout[key];
        }else{
            this.currentTTimemout[key] = value;
        }
    }

    /**
     * Cancels delay
     * @param {string} key
     * @return {int}
     */
    cancelDelay(key){
        let tt = this.tTimeout(key);
        if(tt){
            clearTimeout(tt);
        }
    }

    slugValue(s){
        const vowels = {
            "a": ["á","Á","ä","Ä","à","À","â","Â","ã","Ã"],
            "e": ["é","É","ë","Ë","è","È","ê","Ê"],
            "i": ["í","Í","ï","Ï","ì","Ì","î","Î"],
            "o": ["ó","Ó","ö","Ö","ò","Ò","ô","Ô","õ","Õ"],
            "u": ["ú","Ú","ü","Ü","ù","Ù","û","Û"],
        };
        s = s.toLowerCase();
        s = s.trim();
        s = s.replace(/ /g, "-");
        for(let vowel in vowels){
            for (let i = 0; i < vowels[vowel].length; i++) {
                s = s.replace(new RegExp(vowels[vowel][i], "g"), vowel);
            }
        }
        s = s.replace(/Ç/g, "c");
        s = s.replace(/ç/g, "c");
        s = s.replace(/ñ/g, "n");
        s = s.replace(/Ñ/g, "n");
        s = s.replace(/[^a-z0-9-]+/g, "");
        return s;
    }

    lang(varName,setValue){
        let originalVarName = varName;
        varName = this.slugValue(varName).substring(0, 60).replaceAll('(^-+|-+$)',"");
        if(setValue===undefined){
            setValue = originalVarName;
        }

        let langTexts = localStorage["langTexts"];
        let preferredLang = localStorage["language"];
        if(langTexts && preferredLang){
            let langTextsArray = this.parseJson(langTexts);
            if(langTextsArray[varName]){
                return langTextsArray[varName][preferredLang];
            }else{
                return setValue;
            }
        }
        let path = "/ep/cp/cp-main/get-js-translation";
        const apiRootUrl = GvfConfigService.getValue("apiRootUrl");
        let translate = "";
        $.ajax(
            apiRootUrl+path,
            {
                type: "POST",
                url: path,
                contentType: "application/json",
                async:false,
                data: this.toJson({varName,setValue}),
                cache: false,
                processData: false,
                success: (resp)=>{
                    if(resp.preferredLang){
                        localStorage["language"] = resp.preferredLang;
                    }
                    if(resp.translations){
                        localStorage["langTexts"] = JSON.stringify(resp.translations);
                        if(resp.translations[varName]){
                            translate = resp.translations[varName][resp.preferredLang];
                        }
                    }
                    translate = setValue;
                },
                error: (error)=>{
                    reject(error);
                }
            }
        );
        return translate;
    }
}

const s_GvfService = new GvfService();
export {s_GvfService as GvfService};
