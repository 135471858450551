import {GvfService} from "../../gvf/js/gvf-service";

class FcmService{
    constructor(){

    }

    sendTokenToServer(currentToken){
        if(!this.isTokenSentToServer()){
            GvfService.endpoint("/cp/cp-device/register-token",{token: currentToken}).then(
                () => {
                    this.setTokenSentToServer(true);
                }
            );
        }
    }

    isTokenSentToServer(){
        return window.localStorage.getItem("sentToServer")==="1";
    }

    setTokenSentToServer(sent){
        window.localStorage.setItem("sentToServer",sent?"1":"0");
    }

    requestPermission(vapidKey){
        Notification.requestPermission().then(() => {
            this.messaging.getToken({vapidKey:vapidKey}).then(
                (refreshedToken) => {
                    this.setTokenSentToServer(false);
                    this.sendTokenToServer(refreshedToken);
                }
            ).catch(
                ()=>{

                }
            );
        });
    }

    showNotification(payload){
        if(payload.data && payload.notification){
            let notification = new Notification(payload.notification.title?payload.notification.title:"",payload.notification);
        }
    }

    init(vapidKey,firebaseConfig){
        import("firebase/app").then(
            ({ default: firebase })=>{
                import("firebase/messaging").then(
                    ({ default: firebase2 })=>{
                        firebase.initializeApp(firebaseConfig);

                        try{
                            this.messaging = firebase.messaging();
                            this.messaging.onMessage(
                                (payload)=>{
                                    // Let's check if the browser supports notifications
                                    if(!("Notification" in window)){
                                        alert("This browser does not support desktop notification");
                                    }else if(Notification.permission==="granted"){
                                        this.showNotification(payload);
                                    }else if(Notification.permission!=="denied"){
                                        Notification.requestPermission().then((permission)=>{
                                            // If the user accepts, let's create a notification
                                            if(permission==="granted"){
                                                this.showNotification(payload);
                                            }
                                        });
                                    }
                                }
                            );
                            this.requestPermission(vapidKey);
                        }catch(e){
                            console.log(e);
                        }
                    }
                );
            }
        );
    }
}

const s_FcmService = new FcmService();
export {s_FcmService as FcmService};
